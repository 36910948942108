<template>
    <div v-if="displayComponent" class="cookie-reminder">
        <p>{{ $t('main') }}</p>
        <div class="cookie-reminder__control">
            <Button @click="displayMoreEvent">{{ $t('showDetails') }}</Button>
            <Button @click="acceptCookiesEvent">{{ $t('html.labels.accept') }}</Button>
        </div>
        <Modal v-if="modals.details.display" @modal:cancel="hideDetailsModal">
            <template #header-title>
                <p>{{ $t('modals.details.title') }}</p>
            </template>
            <template #body>
                <p>{{ $t('modals.details.body') }}</p>
            </template>
            <template #footer-other>
                <Button @click="acceptCookiesEvent">{{ $t('html.labels.accept') }}</Button>
            </template>
        </Modal>
    </div>
</template>

<script>
import Button from "./buttons/Button.vue";
import Modal from "./Modal.vue";
import Cookie from "js-cookie";
import {CookieReminderAccepted} from "../classes/Broadcastables.js";

export default {
    components: {Modal, Button},

    i18n: {
        messages: {
            en: {
                main: 'GDPR Cookie Reminder',
                showDetails: 'Details',
                modals: {
                    details: {
                        title: 'Cookie reminder',
                        body: 'This website is using cookies of the "Strictly necessary" and "Preferences" category.'
                    }
                }
            },
            hu: {
                main: 'GDPR Süti Tájékoztató',
                showDetails: 'Részletek',
                modals: {
                    details: {
                        title: 'Süti tájékoztató',
                        body: 'Ez a weblap sütiket használ a következő kategóriákban: "Szigorúan szükséges" és "Preferenciák".'
                    }
                }
            }
        }
    },

    data() {return {
        broadcastCallbackId: null,
        displayComponent: !Cookie.get(this.$configuration.get('vueJs.cookieReminder.cookieName')) ?? true,
        modals: {
            details: {
                display: false
            }
        }
    }},

    mounted() {
        this.broadcastCallbackId = this.$broadcast.add(this.broadcastedCookieReminderEvent.bind(this));
    },

    methods: {
        hideComponent() {
            this.displayComponent = false;
        },

        broadcastedCookieReminderEvent(event) {
            if(event instanceof CookieReminderAccepted) {
                this.hideComponent();
                this.$broadcast.remove(this.broadcastCallbackId);
            }
        },

        acceptCookiesEvent() {
            Cookie.set(this.$configuration.get('vueJs.cookieReminder.cookieName'), true, { expires: 365 });
            this.hideComponent();
            new CookieReminderAccepted().dispatch();
        },

        displayDetailsModal() {
            this.modals.details.display = true;
        },

        hideDetailsModal() {
            this.modals.details.display = false;
        },

        displayMoreEvent() {
            this.displayDetailsModal();
        }
    }
}
</script>
