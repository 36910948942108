<template>
    <Teleport to=".frame-common">
        <div class="modal" :class="class" :id="id" aria-hidden="true" @click="clickedOnBackgroundEvent" ref="background">
            <div class="modal__content">
                <div class="modal__content__header">
                    <slot name="header">
                        <div class="modal__content__header__title">
                            <slot name="header-title"></slot>
                        </div>
                        <div class="modal__content__header__cancel">
                            <slot name="header-cancel">
                                <div @click="cancelModalEvent">×</div>
                            </slot>
                        </div>
                    </slot>
                </div>
                <div class="modal__content__body">
                    <slot name="body"></slot>
                </div>
                <div class="modal__content__footer">
                    <slot name="footer">
                        <div v-if="displayDefaultCancelButton" class="modal__content__footer__cancel">
                            <slot name="footer-cancel">
                                <Button @click="cancelModalEvent">{{ $t('html.forms.cancel') }}</Button>
                            </slot>
                        </div>
                        <div class="modal__content__footer__other">
                            <slot name="footer-other"></slot>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
import "../../scss/components/modal.scss";
import Button from "./buttons/Button.vue";

export default {
    components: {Button},

    props: {
        backgroundClickEvent: {
            required: false,
            type: [Function, Boolean],
            default: true
        },

        displayDefaultCancelButton: {
            required: false,
            type: Boolean,
            default: true
        },

        class: {
            required: false,
            default: null
        },

        id: {
            required: false,
            default: null
        }
    },

    emits: ['modal:cancel'],

    methods: {
        cancelModalEvent() {
            this.$emit('modal:cancel');
        },

        clickedOnBackgroundEvent($event) {
            if($event.target !== this.$refs.background) {
                return;
            }

            switch(typeof this.backgroundClickEvent) {
                case 'boolean':
                    if(this.backgroundClickEvent) {
                        this.cancelModalEvent();
                    }
                    break;
                case 'function':
                    this.backgroundClickEvent($event, this);
                    break;
            }
        }
    }
}
</script>

<style lang="scss">
@import "@scss/components/modal.scss";
</style>
