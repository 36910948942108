<template>
    <LabelledSelect v-model="current" :options="languages" class="language-selector">
        <p class="language-selector_text">{{ $t('html.labels.localization') }}</p>
    </LabelledSelect>
</template>

<script>
import {LabelledSelect} from "@jensarray/library";
import EarthSvg from "../../media/images/vector/earth.svg?component";

export default {
    components: {LabelledSelect, EarthSvg},

    computed: {
        languages() {
            let options = [];

            _.forEach(this.$configuration.get('vueJs.localization.allowed'), (value, key) => {
                options.push({
                    label: value,
                    value: key
                });
            })

            return options;
        },

        current: {
            set(value) {
                this.$configuration.setLocale(value);
            },
            get() {
                return this.$configuration.getLocale();
            }
        }
    }
}
</script>
