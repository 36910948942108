import {useResourceStore} from "../stores/resourceStore.js";
import UserResource from "../classes/resources/UserResource.js";

export default class {
    constructor() {
        this.resourceStore = useResourceStore()
        this.Model = window.frontendConfiguration.user ?
            this.resourceStore.setCurrentUser(new UserResource(_.cloneDeep(window.frontendConfiguration.user)))
                .getCurrentUser() :
            {isLoggedIn() { return false; }};
    }

    install(app, options) {
        /**
         * Example:
         * this.$root.$user
         */
        app.config.globalProperties.$user = this.Model;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const UserProvider = inject('UserProvider')
         */
        app.provide('UserProvider', this.Model);
    }
};
