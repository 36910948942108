import { defineStore } from 'pinia';
import { ref } from 'vue';
import ConstraintResource from "../classes/ConstraintResource.js";
import UserResource from "../classes/resources/UserResource.js";

export const useResourceStore = defineStore('ResourceStore', {
    state: () => ({
        resources: {},
        typeMap: {},
        currentUserUuid: ref(null)
    }),

    actions: {
        _checkTypeKey(resource) {
            if(!this.$state.typeMap.hasOwnProperty(resource.meta.type)) {
                this.$state.typeMap[resource.meta.type] = [];
            }
        },

        _setResource(resource) {
            this.$state.resources[resource.data.uuid] = resource;
            this.$state.typeMap[resource.meta.type].push(resource.data.uuid);
        },

        set(resource) {
            if(! resource instanceof ConstraintResource) {
                throw 'Function accepts instances of "' + ConstraintResource.constructor.name + '" only!';
            }

            this.$patch(() => {
                this._checkTypeKey(resource);
                this._setResource(resource);
            });

            return this;
        },

        setCurrentUser(resource) {
            if(! resource instanceof UserResource) {
                throw 'Function accepts instances of "' + UserResource.constructor.name + '" only!';
            }

            this.$patch((state) => {
                this._checkTypeKey(resource);

                this._setResource(resource);
                state.currentUserUuid = resource.data.uuid;
            });

            return this;
        },

        has(uuid) {
            return this.$state.resources.hasOwnProperty(uuid);
        },


        get(uuid) {
            return this.$state.resources[uuid];
        },

        remove(uuid) {
            delete(this.$state.resources[uuid]);

            return this;
        },

        /**
         * @returns {UserResource|null}
         */
        getCurrentUser() {
            return this.$state.currentUserUuid ? this.get(this.$state.currentUserUuid) : null;
        }
    },
});
