<template>
    <i class="svg-icon" :style="getInlineStyling"></i>
</template>

<script>
export default {
    props: {
        src: {
            required: true,
            type: String
        }
    },

    computed: {
        getInlineStyling() {
            return [
                'mask-image: url(' + this.src + ')',
            ].join(';') + ';';
        }
    }
}
</script>
