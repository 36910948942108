<template>
    <LabelledSelect :modelValue="selected" :options="getOptions" @update:modelValue="changeTheme">{{$t('html.labels.themeSelector')}}</LabelledSelect>
</template>

<script>
import {LabelledSelect} from '@jensarray/library';
import {useThemeStore} from "../stores/themeStore.js";

export default {
    components: {LabelledSelect},

    data() {return {
        selected: null,
        themeStore: (useThemeStore()).initialize()
    };},

    beforeMount() {
        this.selected = this.themeStore.current;
    },

    methods: {
        changeTheme(value) {
            if(value === this.selected) {
                return;
            }

            this.themeStore.setTheme(value);
            this.selected = this.themeStore.current;
        }
    },

    computed: {
        getOptions() {
            let options = [];

            _.forEach(this.$configuration.get('vueJs.themes.allowed'), (value) => {
                options.push({
                    label: this.$t('colorThemes.' + value),
                    value: value
                });
            });

            return options;
        }
    }
}
</script>
