<template>
    <router-link :to="{name: 'guilds.view', params: {guild: resource.data.uuid}}">{{resource.data.name}}</router-link>
</template>
<script>
import GuildResource from "../../../classes/resources/GuildResource.js";

export default {
    props: {
        resource: {
            required: true,
            type: [GuildResource, Object]
        }
    }
}
</script>
