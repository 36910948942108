export default class {
    constructor() {
        this.callbackIndex = 0;
        this.callbacks = {};
    }

    install(app, option) {
        this.app = app;

        this.startListening();

        const exposed = {
            registerCallback: this.registerCallback.bind(this),
            removeCallback: this.removeCallback.bind(this),
        };

        /**
         * Example:
         * this.$root.$reverb
         */
        app.config.globalProperties.$reverb = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const Reverb = inject('Reverb')
         */
        app.provide('Reverb', exposed);
    }

    startListening() {
        if(this.app.config.globalProperties.$user.isLoggedIn()) {
            Echo.private('App.Models.User.' + this.app.config.globalProperties.$user.data.id)
                .listenToAll(this.eventHandler.bind(this));
        }
    }

    eventHandler(name, data) {
        Object.values(this.callbacks).forEach((entry) => {
            if(entry.type === name || entry.type === '*') {
                entry.callback(data);
            }
        });
    }

    /**
     * @param {String} type Serverside broadcast event name or "*" for anything
     * @param {function} callback
     * @returns {integer} callback id
     */
    registerCallback(type, callback) {
        this.callbacks[++this.callbackIndex] = {
            type: type,
            callback: callback
        };

        return this.callbackIndex;
    }

    /**
     * @param {integer} id
     * @returns {this}
     */
    removeCallback(id) {
        delete(this.callbacks[id]);

        return this;
    }
}
