export default class ConstraintResource {
    casts = {
        created_at: Date,
        updated_at: Date,
        deleted_at: Date,
    };

    constructor(resource, name = null) {
        this.data = resource.data;
        this.meta = resource.meta;
        this.name = name;
    }

    initialize() {
        this.__casts();
    }

    __casts() {
        Object.keys(this.casts).forEach((key) => {
            if(this.data[key] !== null) {
                this.data[key] = new this.casts[key](this.data[key]);
            }
        });
    }

    get model() {
        return this.name ?? this.constructor.name.substring(0, this.constructor.name.length - 8); // Where -8 is "Resource"
    }

    get key() {
        return this.data.uuid ?? this.data.id;
    }
}
