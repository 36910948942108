<template>
    <button class="button" @click="click" :disabled="disabled">
        <slot>{{ label }}</slot>
    </button>
</template>

<script>
export default {
    props: {
        click: {
            required: false,
            type: Function,
            default: null
        },

        disabled: {
            required: false,
            type: Boolean,
            default: false
        },

        label: {
            required: false,
            type: [String, Number],
            default: 'Button'
        }
    }
}
</script>
