import { defineStore } from 'pinia';
import Cookies from 'js-cookie';

const cookieName = window.frontendConfiguration.vueJs.themes.cookieName;

export const useThemeStore = defineStore('ThemeStore', {
    state: () => ({
        cookieName: cookieName,
        current: Cookies.get(cookieName) ?? window.frontendConfiguration.vueJs.themes.default ?? 'burgundy',
        allowed: window.frontendConfiguration.vueJs.themes.allowed ?? ['burgundy'],
        fallback: window.frontendConfiguration.vueJs.themes.fallback ?? 'burgundy',
        initialized: false
    }),

    actions: {
        setTheme(name) {
            if(!this.allowed.includes(name)) {
                console.error(`Theme key "${name}" is not allowed! Setting fallback value: "${'colorThemes.' + this.fallback}"`);
                name = this.fallback;
            }

            this.current = name;

            // Set cookie
            Cookies.set(this.cookieName, this.current, {expires: 365});
            axios.defaults.headers.common[this.cookieName] = this.current;

            return this;
        },

        getHtmlClass() {
            return 'theme-color-' + this.current;
        },

        initialize() {
            if(!this.initialized) {
                this.setTheme(this.allowed.includes(this.current) ? this.current : this.fallback);

                axios.defaults.headers.common[this.cookieName] = this.current;

                this.initialized = true;
            }

            return this;
        }
    }
});
