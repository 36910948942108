import ConstraintResource from "../ConstraintResource.js";
import {GuildDependentPermissionList, GuildDependentRoleList, Permission, Role} from "../Permissions.js";
import {useResourceStore} from "../../stores/resourceStore.js";
import GuildResource from "./GuildResource.js";

export default class UserResource extends ConstraintResource {
    /**
     * @param {string|Role} role
     * @param {any} options
     * @returns {boolean}
     */
    hasRole(role, options = null) {
        if(! role instanceof Role && typeof role !== 'string') {
            console.error('Invalid argument as role', role);
            return false;
        }

        return this.resolve(role, this.meta.roles, options);
    }

    /**
     * @param {string|Permission} permission
     * @param {any} options
     * @returns {boolean}
     */
    hasPermission(permission, options = null) {
        if(! permission instanceof Permission && typeof permission !== 'string') {
            console.error('Invalid argument as permission', permission);
            return false;
        }

        return this.resolve(permission, this.meta.permissions, options);
    }

    resolve(source, category, options) {
        if(!category) {
            console.error('Cannot resolve user ability without "category"! Was it loaded properly?');
            return false;
        }

        switch(typeof source) {
            case 'string':
                return this.resolveGluedString(source, category, options);
            case 'function':
                return this.resolveCallback(source, options);
            default: // object, array
                if(source instanceof GuildDependentPermissionList || source instanceof GuildDependentRoleList) {
                    source = source.toArray(options.guild ?? options.to.params.guild);
                }

                if(source instanceof Role || source instanceof Permission) {
                    return this.resolve(source.toString(), category, options);
                }

                let resolved = false;

                Object.keys(source).forEach((key) => { // Array
                    if(resolved) {
                        return;
                    }

                    let value = source[key];

                    resolved = this.resolve(value, category, options);
                });

                return resolved;

        }
    }

    resolveCallback(source, options) {
        return source(this.exposed, options);
    }

    resolveGluedString(source, category, options) {
        if (options?.hasOwnProperty('route')) {
            if (options.route.hasOwnProperty('to')) {
                for (let key in options.route.to.params) {
                    source = source.replace(`{${key}}`, options.route.to.params[key]);
                }
            }
        }

        source = source.indexOf(':') !== -1 ?
            source.split(':') :
            [0, source];

        return category[source[0]]?.includes(source[1]) ?? false;

    }

    isLoggedIn() {
        const resourceStore = useResourceStore();

        if((resourceStore.getCurrentUser()?.data.uuid ?? false) === this.data.uuid) {
            return true;
        }

        return false; // TODO: check through of the server
    }

    /**
     *
     * @param {GuildResource} guildResource
     * @returns {boolean}
     */
    isGuildMemberOf(guildResource) {
        if(!guildResource instanceof GuildResource) {
            throw 'GuildResource is expected!';
        }

        return  !!_.find(this.data.guild_memberships, (guild) => {
            return guild.data.id === guildResource.data.id;
        });
    }
}
