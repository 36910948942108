<template>
    <Scrollable :horizontal="false">
        <nav class="navigation" role="navigation">
            <NavigationNode class="navigation__list" :routes="routes"/>
        </nav>
    </Scrollable>
</template>

<script>
import NavigationNode from "./NavigationNode.vue";
import {Scrollable} from "@jensarray/library";

export default {
    components: {NavigationNode, Scrollable},
    emits: ['navigation'],

    computed: {
        routes() {
            return this.$router.options.routes;
        }
    }
}
</script>
