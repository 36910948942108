class Route {
    constructor(parameters) {
        this.name = parameters.name ?? null;
        this.path = parameters.path ?? null;
        this.component = parameters.component ?? null;
        this.children = parameters.children ?? null;
        this.beforeResolve = parameters.beforeResolve ?? null;
        this.beforeEnter = parameters.beforeEnter ?? null;

        this.meta = {
            hidden: parameters.hidden ?? false,
            roles: parameters.roles ?? [],
            permissions: parameters.permissions ?? [],
            label: parameters.label ?? null,
            translate: true, // translate label
            iconBefore: parameters.iconBefore ?? null,
            iconAfter: parameters.iconAfter ?? null,
            componentBefore: parameters.componentBefore ?? null,
            componentAfter: parameters.componentAfter ?? null
        };
    }
}

class RedirectRoute {
    constructor(parameters) {
        this.name = parameters.name ?? null;
        this.path = parameters.path ?? null;
        this.redirect = parameters.redirect;

        this.meta = {
            hidden: parameters.hidden ?? true,
            roles: parameters.roles ?? [],
            permissions: parameters.permissions ?? [],
            label: parameters.label ?? null,
            translate: true, // translate label
            iconBefore: parameters.iconBefore ?? null,
            iconAfter: parameters.iconAfter ?? null,
        };
    }
}

export {
    Route,
    RedirectRoute
};
