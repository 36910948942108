class RoleOrPermission {
    /**
     * @param {string} name
     * @param {resource|null} guild
     */
    constructor(name, guild) {
        this.name = name;
        this.guild = guild;
        this.glue = ':';
        this.keyName = 'uuid';
    }

    toString() {
        return this.guild ?
            this.guild.data[this.keyName] + this.glue + this.name :
            this.name;
    }
}

export class Permission extends RoleOrPermission {}

export class Role extends RoleOrPermission {}

class GuildDependentList {
    constructor(list, guild, relatedTo) {
        if(!list || !Array.isArray(list)) {
            throw 'First argument is not a list!';
        }

        this.list = list;
        this.guild = guild ?? null;
        this.relatedTo = relatedTo;
    }

    toArray(guild) {
        if(!this.guild && !guild) {
            throw 'Guild was not provided! Unable to resolve.'
        }

        let list = [];

        if(!this.list?.length) {
            return list;
        }

        for(let key in this.list) {
            let value = this.list[key];

            list.push(new this.relatedTo(value, this.guild ?? guild));
        }

        return list;
    }
}

export class GuildDependentPermissionList extends GuildDependentList {
    constructor(props, guild) {
        super(props, guild, Permission);
    }
}

export class GuildDependentRoleList extends GuildDependentList {
    constructor(props, guild) {
        super(props, guild, Role);
    }
}
