import {RedirectRoute, Route} from "../classes/RouteTypes";

export default [
    // Errors
    new Route({path: '/404', name: 'http.status.404', hidden: true, component: () => import('../pages/common/errors/E_404')}),
    new Route({path: '/403', name: 'http.status.403', hidden: true, component: () => import('../pages/common/errors/E_403')}),
    new Route({path: '/401', name: 'http.status.401', hidden: true, component: () => import('../pages/common/errors/E_401')}),

    new Route({path: '/login', label: 'navigation.login', name: 'login', hidden: ($root) => {
            return $root.$user.isLoggedIn();
        },
        component: () => import('../pages/common/login/Frame'),
        iconBefore: new URL('@media/images/vector/tower.svg', import.meta.url).href
    }),

    // Otherwise
    new Route({path: '/:pathMatch(.*)*', hidden: true, component: () => import('../pages/common/errors/E_404')})
];
